import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-home-eventos',
  templateUrl: './home-eventos.component.html',
  styleUrls: ['./home-eventos.component.css']
})
export class HomeEventosComponent implements OnInit {

  @Input() showMePartially: boolean;

  slideConfig: Object;
  prox_partidos = [];
  prox_partido = [];
  resultados = [];
  boton_comprar_ahora: any = '';
  tiempo_que_falta: string;
  tiempo_index: number;

  counterIndex = -1;

  constructor(
    public commonService: CommonService,
    public dataService: DataService,
  ) {

  }

  ngOnInit() {

    Promise.all([
      this.dataService.getProxPartido().toPromise()
        .then(prox_partido => {
          this.prox_partido = prox_partido;
          if (prox_partido) {
            this.counterIndex = this.commonService.initCounter(this.prox_partido['fecha']);
          }
        }).catch(err => {
          //console.log('err', err);
        }),

      this.dataService.getTabla_resultados().toPromise()
        .then(resultados => {
          this.resultados = resultados;
        }),

      this.dataService.getProxPartidos(10).toPromise()
        .then(proxpartidos => {
          this.prox_partidos = proxpartidos;
          if (proxpartidos.length) {
            this.tiempo_index = this.commonService.initCounter(proxpartidos[0].fecha);
          }
        }),
    ]).then();

  }

}
