import {Component, Input, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';

@Component({
    selector: 'app-home-futfem-slider',
    templateUrl: './home-futfem-slider.component.html',
    styleUrls: ['./home-futfem-slider.component.css']
})
export class HomeFutfemSliderComponent implements OnInit {

    @Input() showMePartially: boolean;

    fichas_slides = [];

    constructor(
        public dataService: DataService
    ) {
    }

    ngOnInit() {
        this.dataService.getJugadoresFutFemHome().toPromise()
            .then(jugadores => {
                this.fichas_slides = jugadores['results'];
            });
    }
}
