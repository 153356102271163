import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-home-palmares',
  templateUrl: './home-palmares.component.html',
  styleUrls: ['./home-palmares.component.css']
})
export class HomePalmaresComponent implements OnInit {

  @Input() showMePartially: boolean;
  @Input() mizona: any;


  palmares: any[] = [];

  constructor(
    public dataService: DataService
  ) {

  }

  ngOnInit() {
      this.dataService.getZonasPaginas().toPromise()
        .then(torneos => {
          for (const torneo of torneos['results']) {
            if (torneo.identificador === 'z_torneo') {
              this.palmares = torneo.contenido;
            }
          }
        });
  }

}
