import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';

export interface NavItem {
  label: string;
  routerLink?: string[];
  queryParams?: Object;
  active?: boolean;
  icon?: string;
  items?: NavItem[];
}

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.css']
})
export class NavItemComponent implements OnInit {

  @Input() navItems: NavItem[];
  @Input() display: boolean;

  constructor(
    private _router: Router,
    private _cs: CommonService
   ) {
   }

  ngOnInit() {
  }

  navigateToLink(item) {
    this._cs.toggleMenu();
      if (item['externalLink'] && item['externalLink'].length) {
          window.open(item['externalLink'], '_blank');
      } else {
          this._router.navigate([item['routerLink']], {queryParams: item['queryParams']});
      }
  }
}
