import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pagzone',
  templateUrl: './pagzone.component.html',
  styleUrls: ['./pagzone.component.css']
})
export class PagzoneComponent implements OnInit {

  @Input() data;

  constructor() { }

  ngOnInit() {
  }

}
