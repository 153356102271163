import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {MaterialModule} from '../material/material.module';
import {SlickCarouselModule} from 'ngx-slick-carousel';

import {BannerComponent} from '../components/shared/banner/banner.component';
import {BreadcrumbComponent} from '../components/shared/breadcrumb/breadcrumb.component';
import {MediaViewerComponent} from '../components/shared/media-viewer/media-viewer.component';
import {PagzoneComponent} from '../components/shared/pagzone/pagzone.component';

import {NoticiasCardComponent} from '../components/noticias/noticias-card/noticias-card.component';
import {GaleriasCardComponent} from '../components/galerias/galerias-card/galerias-card.component';
import {LoadingComponent} from '../components/shared/loading/loading.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PopupComponent} from '../components/shared/popup/popup.component';


// import { SlickModule } from 'ngx-slick';
// SlickModule.forRoot()

@NgModule({
  declarations: [
    BannerComponent,
    BreadcrumbComponent,
    NoticiasCardComponent,
    GaleriasCardComponent,
    MediaViewerComponent,
    PagzoneComponent,
    LoadingComponent,
    PopupComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    AngularFontAwesomeModule,
    SlickCarouselModule,
    ReactiveFormsModule,
  ],
  exports: [
    BannerComponent,
    BreadcrumbComponent,
    NoticiasCardComponent,
    MediaViewerComponent,
    PagzoneComponent,
    GaleriasCardComponent,
    AngularFontAwesomeModule,
    SlickCarouselModule,
    LoadingComponent,
    FormsModule,
    ReactiveFormsModule,
    PopupComponent
  ],
  entryComponents: [
    PopupComponent
  ]
})
export class SharedModule {
}
