import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

export interface PopupData {
  image: string;
  link: string;
}

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  slideConfig = {};
  slides = [];

  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopupData[]
  ) {
    this.slides = data;
  }

  ngOnInit() {
    this.slideConfig = {slidesToShow: 1, slidesToScroll: 1, dots: false, autoplay: true, autoplaySpeed: 5000};
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
