import { Component, OnInit, Input } from '@angular/core';
import { INoticiaCard } from '../../../models/noticia';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-noticias-card',
  templateUrl: './noticias-card.component.html',
  styleUrls: ['./noticias-card.component.css']
})
export class NoticiasCardComponent implements OnInit {

  @Input() item: INoticiaCard;

  descr = '';
  constructor(
    private data: DataService
  ) { }

  truncateString = (string, maxLength = 50) => {
    if (!string) return null;
    const showDots = string.length > maxLength;
    return `${string.substring(0, maxLength)}${showDots ? '...' : ''}`;
  };

  ngOnInit() {
    this.descr = this.data.toHTML(this.item['description']);
  }

}
