
import { Component, OnInit, Input } from '@angular/core';
import { IGaleriaCard, } from '../../../models/galeria';
import { DataService } from '../../../services/data.service';

@Component({
    selector: 'app-home-galerias',
    templateUrl: './home-galerias.component.html',
    styleUrls: ['./home-galerias.component.css']
})

export class HomeGaleriasComponent implements OnInit {

    @Input() showMePartially: boolean;

    public selectedFiltroGaleria: string;

    items_subgalerias: any[];
    galeriasArr: IGaleriaCard[];
    subgal: any[] = [];

    constructor(
        public dataService: DataService
    ) {

    }

    ngOnInit() {
        //this.getGalerias('primer-equipo');
        //this.getGalerias('todos');
        this.getGaleriasHome('todos');
    }

    getGalerias(filtro: string) {
        this.dataService.getCategories().toPromise().then(cats => {
            //console.log(filtro);
            //console.log(cats);
            const _filtro = (filtro === 'todos') ? 0 : cats['results'].filter( item => item['nombre'].toLowerCase() === filtro.toLowerCase() )[0]['id'];
            this.dataService.getGalleries(_filtro, 0).toPromise().then(data => {
                this.galeriasArr = data['results'];
                //console.log(this.galeriasArr);
                for (let i = 0; i < data['results'].length; i++) {
                    this.dataService.getSubgaleria(data['results'][i].id).toPromise().then(subgalerias => {
                        this.items_subgalerias = subgalerias;
                        for (let a = 0; a < subgalerias.length; a++) {
                            this.subgal.push({
                                c_imagen: subgalerias[a].c_imagen,
                                c_galeria: subgalerias[a].c_galeria
                            });
                        }
                    });
                }
            });
        });
        this.selectedFiltroGaleria = filtro;
    }


    getGaleriasHome(filtro: string) {
        this.dataService.getCategories().toPromise().then(cats => {
            //console.log(filtro);
            //console.log(cats);
            const _filtro = (filtro === 'todos') ? 0 : cats['results'].filter( item => item['nombre'] === filtro )[0]['id'];
            this.dataService.getGalleriesHome(_filtro, 0).toPromise().then(data => {
                this.galeriasArr = data['results'];
                console.log(this.galeriasArr);
                for (let i = 0; i < data['results'].length; i++) {
                    this.dataService.getSubgaleria(data['results'][i].id).toPromise().then(subgalerias => {
                        this.items_subgalerias = subgalerias;
                        for (let a = 0; a < subgalerias.length; a++) {
                            this.subgal.push({
                                c_imagen: subgalerias[a].c_imagen,
                                c_galeria: subgalerias[a].c_galeria
                            });
                        }
                    });
                }
            });
        });
        this.selectedFiltroGaleria = filtro;
    }

}

