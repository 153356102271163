import { Component, OnInit, Input } from '@angular/core';
import { INoticiaCard, } from '../../../models/noticia';
import { DataService } from '../../../services/data.service';

import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home-noticias',
  templateUrl: './home-noticias.component.html',
  styleUrls: ['./home-noticias.component.css']
})
export class HomeNoticiasComponent implements OnInit {

  @Input() showMePartially: boolean;

  public selectedFiltro = '';

  noticiasArr: INoticiaCard[];
  zona_notic_ver = [];
  boton_comprar_ahora: any = '';
  zona_palmares = [];

  constructor(
    private sanitizer: DomSanitizer,
    public dataService: DataService
  ) {

  }

  ngOnInit() {

      //this.getNoticias('primer-equipo');
      this.getNoticias('todos');

      this.dataService.getZonasPaginas().toPromise()
        .then(zonas => {
          for (const zona of zonas.results) {
            if (zona.identificador === 'prim_hor') {
              // this.zona_prim_hor = zona;
            } else if (zona.identificador === 'hom_ban_la') {
              this.zona_notic_ver = zona;
            } else if (zona.identificador === 'comprar_ah') {
              this.boton_comprar_ahora = this.sanitizer.bypassSecurityTrustUrl(zona.contenido.replace('<p>', ' ').replace('</p>', ' '));
            } else if (zona.identificador === 'z_torneo') {
              this.zona_palmares = zona.contenido;
            }
          }
        });
  }

  getNoticias(filtro: string) {

    this.dataService.getCategories().toPromise().then(cats => {
      const _filtro = (filtro === 'todos') ? 0 : cats['results'].filter( item => item['nombre'] === filtro )[0]['id'];
      this.dataService.getNoticiasListHome(_filtro, 0).subscribe(data => {
        this.noticiasArr = data['results'];
      });
    });
    this.selectedFiltro = filtro;
  }
}
