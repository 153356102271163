import { Component, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  links: any[];
  searchDisplay = false;
  menuToken = '';
  sIndex: number = null;
  submenu: any[] = [];
  link_entradas: any = '';

  constructor(
    private sanitizer: DomSanitizer,
    private _data: DataService,
    private router: Router,
    public cs: CommonService
  ) { }

  ngOnInit() {
    this._data.getMenu().subscribe(
      data => {
        this.links = data;
      });

    this._data.getZonasPaginas_especificas('comprar_ah').subscribe(
      data => {
        this.link_entradas = this.sanitizer.bypassSecurityTrustUrl(data[0].contenido.replace('<p>', ' ').replace('</p>', ' '));
      });

  }
  toggleSearchPanel() {
    this.searchDisplay = !this.searchDisplay;
  }

  // toggleMenu() {
  //   this.menuToken = (this.menuToken === '') ? 'displayMenu' : '';
  // }

  onHome() {
    this.router.navigate(['/']);

    let menuactivos = document.querySelectorAll('.mat-button')

    for (let i = 0; i < menuactivos.length; i++) {

      menuactivos[i].classList.remove('active');

    }

  }

  addOneSublevel(event, item, i) {
    if (item.items) {
      if (this.submenu.length && this.submenu[0].index === i) {
        this.submenu = [];
        event.target.classList.remove('active');
      } else {
        this.submenu = [];
        this.submenu.push({ items: item.items, index: i });
        for (let ii = 0; ii < event.target.parentElement.parentElement.children.length; ii++) {
          event.target.parentElement.parentElement.children[ii].children[0].classList.remove('active');
        }
        event.target.classList.add('active');
      }
    } else {
      if (item['externalLink'] && item['externalLink'].length) {
        window.open(item['externalLink'], '_blank');
      } else {
        this.router.navigate([item['routerLink']], { queryParams: item['queryParams'] });
      }
    }
  }

  changeSublevel(event, item2, i) {
    for (let ii = 0; ii < event.target.parentElement.children.length; ii++) {
      event.target.parentElement.children[ii].classList.remove('active');
    }
    for (let ii = i + 1; ii < this.submenu.length; ii++) {
      this.submenu.pop();
    }
    if (item2.items) {
      this.submenu[i + 1] = { items: item2.items, index: i };
      event.target.classList.add('active');
    } else {
        if (item2['externalLink'] && item2['externalLink'].length) {
            window.open(item2['externalLink'], '_blank');
        } else {
            this.router.navigate([item2['routerLink']], {queryParams: item2['queryParams']});
        }
      this.submenu = [];
    }
  }
  navigateToLink(item) {
      if (item['externalLink'] && item['externalLink'].length) {
          window.open(item['externalLink'], '_blank');
      } else {
          this.router.navigate([item['routerLink']], {queryParams: item['queryParams']});
      }
  }
}
