import { Component, Input } from '@angular/core';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-home-ultimoresultado',
  templateUrl: './home-ultimoresultado.component.html',
  styleUrls: ['./home-ultimoresultado.component.css']
})
export class HomeUltimoresultadoComponent {

  @Input() ultimo_resultado: any[];

  prox_partidos = [];
  prox_partido = [];
  resultados = [];

  constructor(
    public dataService: DataService
  ) { }
}
