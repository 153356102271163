import {Component, OnInit} from '@angular/core';
import {DataService} from './../../../services/data.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    footer1: any;
    year: number;
    footerId: string;

    constructor(
        private dataService: DataService,
        private router: Router
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(event => {
            const fid = this.getFooterId(event['url']);
            console.log('url', event['url']);
            if (this.footerId !== fid) {
                this.footerId = fid;
                this.loadFooter(fid);
            }
        });
    }

    ngOnInit() {
        this.year = new Date().getFullYear();
    }

    private getFooterId(url: string): string {
        if (url.startsWith('/plantilla/primer-equipo')) {
            return 'footer-pri';
        } else if (url.startsWith('/plantilla/divisiones-menores')) {
            return 'footer-men';
        } else if (url.startsWith('/plantilla/voley')) {
            return 'footer-vol';
        } else if (url.startsWith('/plantilla/femenino')) {
            return 'footer-fem';
        } else {
            return 'footer1';
        }
    }

    private loadFooter(id: string) {
        this.dataService.getZonasPaginas_especificas(id).toPromise()
            .then(resp => {
                this.footer1 = resp[0].contenido;
            });
    }
}
