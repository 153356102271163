import { Component, OnInit, Input } from '@angular/core';
import { IBreadcrumb } from '../../../models/shared';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  @Input() items: IBreadcrumb[];

  constructor() {
  }

  ngOnInit() {
  }

}
