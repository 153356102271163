import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { IBanner, IBreadcrumb } from '../../models/shared';
import { DataService } from '../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-fundacion',
  templateUrl: './fundacion.component.html',
  styleUrls: ['./fundacion.component.css']
})
export class FundacionComponent implements OnInit, AfterViewInit {

  @ViewChild('scrollpanel') public scrollpanel: ElementRef;

  banner_item: IBanner = {
    title: 'fundación',
    img: 'assets/img/banner.jpg'
  };
  breadcrumb: IBreadcrumb[];
  anios: string[];
  scroller = 0;
  content: Object;
  html: any;
  publ: any[];
  act_btn = 0;
  zona_notic_ver = [];


  constructor(
    private _data: DataService,
    private _route: ActivatedRoute,
    private _goto: Router
  ) { }

  ngOnInit() {
    this.breadcrumb = [
      { label: 'Home', url: '/' },
      { label: 'El club' },
      { label: 'Historia' }
    ];
    this._data.getFundacionAnios().subscribe(anios => {
      this.anios = anios;
      this._route.queryParams.subscribe(anio => {
        const a = anio['a'];
        if (a !== undefined && a !== null ) {
          this.act_btn = anios.indexOf(a);
          this._getData(a, this.act_btn);
        } else {
          this._getData(this.anios[0], this.act_btn);
        }
      });
    });
    this._data.getZonasPaginas().toPromise()
      .then(zonas => {
        for (const zona of zonas) {
          if (zona.identificador === 'hom_ban_la') {
            this.zona_notic_ver = zona;
          }
        }
    });
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }
  private _getData (anio: string, id: number) {
    this._data.getFundacionContent(anio).subscribe(content => {
      this.content = content;
      this.html = content['html'];
      // this.scrollpanel.nativeElement.scrollLeft = this.scrollpanel.nativeElement.children[id].offsetLeft;
    });
  }
  scrollAnio(direction: string) {
    if (direction === 'next') {
      this.scrollpanel.nativeElement.scrollBy({
        top: 0,
        left: 44,
        behavior: 'smooth'
      });
    } else {
      this.scrollpanel.nativeElement.scrollBy({
        top: 0,
        left: -44,
        behavior: 'smooth'
      });
    }
  }
  toggleAnio( index, anio ) {
    this.act_btn = index;
    this._goto.navigate(['/historia'], { queryParams: { a: anio } });
  }
}
