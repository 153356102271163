import {AfterViewInit, Component} from '@angular/core';
import {Configuration} from './configuration';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {PopupComponent} from './components/shared/popup/popup.component';
import {MatDialog} from '@angular/material';
import {DataService} from './services/data.service';

declare let gtag: (property: string, value: any, configs: any) => {};

// declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements AfterViewInit {
  title = 'app';

  constructor(
    private _configuration: Configuration,
    private router: Router,
    public dialog: MatDialog,
    public dataService: DataService,
  ) {
    const navEndEvents$ = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      );

    navEndEvents$.subscribe((event: NavigationEnd) => {
      gtag('config', environment.googleAnalyticsId, {
        'page_path': event.urlAfterRedirects
      });
    });
  }

  ngAfterViewInit() {
    this.dataService.getPopups().toPromise()
      .then(data => {
        if (data.length > 0) {
          this.openDialog(data);
        }
      });
  }

  openDialog(data: any[]): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '800px',
      // height: '600px',
      panelClass: 'popup-dialog',
      data: data
    });

    /*dialogRef.afterClosed().subscribe(result => {
    });*/
  }
}
