import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* CONFIG */
import { Configuration, ConfigModule } from './configuration';
import { AppRoutingModule } from './app-routing.module';

/* MODULES */
import { MaterialModule } from './material/material.module';
import { SharedModule } from './shared/shared.module';

/* SERVICES */
import { CommonService } from './services/common.service';
import { DataService } from './services/data.service';

/* COMPONENTS */
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/master/header/header.component';
import { FooterComponent } from './components/master/footer/footer.component';
import { ComponentsComponent } from './components/components.component';

import { MenuComponent } from './components/master/menu/menu.component';
import { NavItemComponent } from './components/master/menu/nav-item/nav-item.component';
import { Pag404Component } from './components/pag404/pag404.component';
import { FundacionComponent } from './components/fundacion/fundacion.component';

import { HomeGaleriasComponent } from './components/home/home-galerias/home-galerias.component';
import { HomeNoticiasComponent } from './components/home/home-noticias/home-noticias.component';
import { HomePalmaresComponent } from './components/home/home-palmares/home-palmares.component';
import { HomeFichasComponent } from './components/home/home-fichas/home-fichas.component';
import { HomeEventosComponent } from './components/home/home-eventos/home-eventos.component';
import { HomeSponsorsComponent } from './components/home/home-sponsors/home-sponsors.component';
import { HomeUltimoresultadoComponent } from './components/home/home-ultimoresultado/home-ultimoresultado.component';
import { HomeFutfemSliderComponent } from './components/home/home-futfem-slider/home-futfem-slider.component';
import { HomeVoleySliderComponent } from './components/home/home-voley-slider/home-voley-slider.component';

/* EXTRAS */

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ComponentsComponent,
    MenuComponent,
    NavItemComponent,
    Pag404Component,
    FundacionComponent,
    HomeGaleriasComponent,
    HomeNoticiasComponent,
    HomePalmaresComponent,
    HomeFichasComponent,
    HomeEventosComponent,
    HomeSponsorsComponent,
    HomeUltimoresultadoComponent,
    HomeFutfemSliderComponent,
    HomeVoleySliderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    SharedModule
  ],
  providers: [
    Configuration,
    ConfigModule.init(),
    CommonService,
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
