import { Component, OnInit, Input } from '@angular/core';
import { IGaleriaCard } from '../../../models/galeria';


@Component({
  selector: 'app-galerias-card',
  templateUrl: './galerias-card.component.html',
  styleUrls: ['./galerias-card.component.css']
})
export class GaleriasCardComponent implements OnInit {

  @Input() item: IGaleriaCard;

  constructor() { }

  ngOnInit() {
  }

}
