import { Component, Input } from '@angular/core';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-home-sponsors',
  templateUrl: './home-sponsors.component.html',
  styleUrls: ['./home-sponsors.component.css']
})

export class HomeSponsorsComponent {

  @Input() zona: any[];

  constructor(
    public dataService: DataService
  ) {

  }
}
