import { Injectable, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { log } from 'util';

@Injectable()
export class Configuration {

  private _config: Object;
  private _env: string;
   public TNEURL: string;
   public ApiURL: string;
    public headers = new HttpHeaders();
    public proxyurl = 'https://cors-anywhere.herokuapp.com/';

    constructor(
      private http: HttpClient
    ) {
      this.headers.append('Access-Control-Allow-Origin', '*');
      this.headers.append('Content-Type', 'application/json');
      this.headers.append('Accept', 'application/json');
    }
    load() {
      return new Promise((resolve, reject) => {
          this.http.get('assets/json/config.json')
              .subscribe((data) => {
                  this._config = data;
                  resolve(true);
              },
              (error: any) => {
                  console.error(error);
                  return Observable.throw(error.json().error || 'Server error');
              });
      });
  }
  getApi(key: string): string {
    return this._config['urls'][key];
  }
  get(key: any) {
      return this._config[key];
  }
}

export function ConfigFactory(config: Configuration) {
  return () => config.load();
}

export function init() {
  return {
      provide: APP_INITIALIZER,
      useFactory: ConfigFactory,
      deps: [Configuration],
      multi: true
  };
}

const ConfigModule = {
  init: init
};

export { ConfigModule };
