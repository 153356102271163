import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { timeout } from 'q';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  loading: any;

  close: boolean;
  constructor(
    public ls: LoadingService
  ) {
    this.close = false;
  }

  ngOnInit() {
    setTimeout(() => this.close = true, 1000);
  }

}
