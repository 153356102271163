
import { Component, OnInit, AfterViewInit } from '@angular/core';
// import { INoticiaCard, } from '../../models/noticia';
// import { IGaleriaCard, } from '../../models/galeria';

import { DataService } from './../../services/data.service';
import { CommonService } from '../../services/common.service';
import { LoadingService } from 'src/app/services/loading.service';

import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  counterIndex = -1;

  slides = [];
  fichas_slides = [];
  slideConfig: Object;
  // noticiasArr: INoticiaCard[];
  // prox_partidos = [];
  prox_partido = {};
  ultimo_resultado = [];
  resultados = [];
  zona_prim_hor = [];
  zona_notic_ver = [];
  boton_comprar_ahora: any = '';
  tiempo_que_falta: string;
  tiempo_index: number;
  subgal: any[] = [];
  zona_palmares = [];

  showVar1 = false;
  showVar2 = false;
  showVar3 = false;
  showVar4 = false;
  showVar5 = false;
  showVar6 = false;
  showVar7 = false;

  constructor(
    private sanitizer: DomSanitizer,
    public commonService: CommonService,
    public dataService: DataService,
    private ls: LoadingService
  ) {
    this.ls.loading = true;
  }

  ngOnInit() {

    // window.addEventListener('scroll', this.scroll, true);

    // this.dataService.getProxPartidos(10).toPromise()
    //   .then(proxpartidos => {
    //     this.prox_partidos = proxpartidos;
    //     if (proxpartidos.length) {
    //       this.tiempo_index = this.commonService.initCounter(proxpartidos[0].fecha);
    //   }
    // }),
    this.slideConfig = { slidesToShow: 1, slidesToScroll: 1, dots: true, autoplay: true, autoplaySpeed: 5000 };
    Promise.all([
      this.dataService.getBanner().toPromise().then(banners => {
        this.slides = banners['results'];
      }),
      this.dataService.getProxPartido().toPromise()
        .then(prox_partido => {
          if (prox_partido !== undefined) {
            this.prox_partido = prox_partido;
            this.counterIndex = this.commonService.initCounter(this.prox_partido['fecha']);
          }
        }),
      this.dataService.getZonasPaginas().toPromise()
        .then(zonas => {
          for (const zona of zonas.results) {
            if (zona.identificador === 'prim_hor') {
              this.zona_prim_hor = zona;
            } else if (zona.identificador === 'hom_ban_la') {
              this.zona_notic_ver = zona;
            } else if (zona.identificador === 'comprar_ah') {
              this.boton_comprar_ahora = this.sanitizer.bypassSecurityTrustUrl(zona.contenido.replace('<p>', ' ').replace('</p>', ' '));
            } /*else if (zona.identificador === 'z_torneo') {
              this.zona_palmares = zona.contenido;
            }*/
          }
        }),
    ])
    .then(() => this.ls.loading = false)
    .then(() => this.dataService.getUltimoResultado().toPromise()
      .then(ultimo_resultado => {
        this.ultimo_resultado = ultimo_resultado;
    }))
    .then(() => this.dataService.getZonasPaginas().toPromise()
      .then(zonas => {
        for (let i = 0; i < zonas['count']; i++) {
          if (zonas['results'].identificador === 'prim_hor') {
            this.zona_prim_hor = zonas['results'];
            break;
          }
        }
    }))
    .then(() => this.showVar3 = true)
    .then(() => this.showVar4 = true)
    .then(() => this.showVar5 = true)
    .then(() => this.showVar6 = true)
    .then(() => this.showVar7 = true);
  }
  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  offset(el) {
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

}
