import { Component, OnInit, Input } from '@angular/core';
import { IBanner } from '../../../models/shared';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  @Input() item: IBanner;

  constructor() { }

  ngOnInit() {
  }

}
