import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './components/home/home.component';
import {Pag404Component} from './components/pag404/pag404.component';
import {FundacionComponent} from './components/fundacion/fundacion.component';

const routes: Routes = [
    {path: '', pathMatch: 'full', component: HomeComponent},
    {path: 'eventos', loadChildren: './components/eventos/eventos.module#EventosModule'},
    {path: 'plantilla', loadChildren: './components/fichas/fichas.module#FichasModule'},
    {path: 'staff', loadChildren: './components/staff/staff.module#StaffModule'},
    {path: 'galerias', loadChildren: './components/galerias/galerias.module#GaleriasModule'},
    {path: 'noticias', loadChildren: './components/noticias/noticias.module#NoticiasModule'},
    {path: 'pagina', loadChildren: './components/jerarquia/jerarquia.module#JerarquiaModule'},
    {path: 'historia', component: FundacionComponent},
    {path: 'revista-digital', loadChildren: './components/revista-digital/revista-digital.module#RevistaDigitalModule'},
    {path: '**', component: Pag404Component}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
