import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {IPlantillaCard} from '../models/plantilla';
import {IEventoDet} from '../models/eventos';
import {IMediaviewer} from '../models/shared';
import {Observable} from 'rxjs';
import {IGaleriaCard} from '../models/galeria';
import {INoticiaCard} from '../models/noticia';
import {Configuration} from '../configuration';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private url: string;
  private _headers: HttpHeaders;
  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  private _cats: Object[];
  public fundacion_arr: Object[];

  constructor(
    private _http: HttpClient,
    private _config: Configuration
  ) {

    this.url = this._config.getApi(environment.url);
    this._headers = new HttpHeaders();
    this._headers.append('Access-Control-Allow-Origin', '*');
    this._headers.append('Access-Control-Allow-Methods', '*');
    this._headers.append('Content-Type', 'application/json');
    this._headers.append('Accept', 'application/json');
  }

  public getCategories() {
    return this._http.get<any[]>(this.url + 'categorias/');
  }

  public getMenu() {
    return this._http.get<any[]>('assets/json/menu.json');
  }

  public getSlides() {
    return this._http.get<any[]>('assets/json/slides.json');
  }

  public getProxPartido() {
    return this._http.get<any>(this.url + 'prox_partidos/1/').pipe(map(res => res[0]));
  }

  public getProxPartidos(num?: number) {
    return this._http.get<any>(`${ this.url }prox_partidos/${ num }/`);
  }

  public getUltimoResultado() {
    return this._http.get<any>(this.url + 'ultimos_partidos/1/').pipe(map(res => res[0]));
  }

  public getNoticiasList(filtro: number, offset?: number) {
    const url = (filtro !== 0) ?
      `${ this.url }noticia/?limit=6&offset=${ offset }&categoria=${ filtro }` :
      `${ this.url }noticia/?limit=6&offset=${ offset }`;
    //`${ this.url }noticia/?limit=12&offset=${ offset }&categoria=${ filtro }` :
    //`${ this.url }noticia/?limit=12&offset=${ offset }`;
    return this._http.get<any[]>(url).pipe(
      map((data: INoticiaCard[] | string) => (typeof data === 'string') ? [] : data)
    );
  }

  public getNoticiasListHome(filtro: number, offset?: number) {
    const url = (filtro !== 0) ?
      //`${ this.url }noticia/?limit=6&offset=${ offset }&categoria=${ filtro }` :
      //`${ this.url }noticia/?limit=6&offset=${ offset }`;
      `${ this.url }noticia/?limit=6&offset=${ offset }&categoria=${ filtro }` :
      `${ this.url }noticia/?limit=6&offset=${ offset }`;
    return this._http.get<any[]>(url).pipe(
      map((data: INoticiaCard[] | string) => (typeof data === 'string') ? [] : data)
    );
  }

  public getNoticiasRecientes(num_noticias: number = 3) {
    const body = {
      num_noticias: num_noticias,
      categoria: 0
    };
    return this._http.post<any[]>(this.url + 'ultimas_noticias/', body, {headers: this._headers}).pipe(
      map((data: any[] | string) => {
        if (typeof data === 'string') {
          return [];
        }
        const res: IMediaviewer[] = [];
        data.forEach(item => {
          res.push({
            img: item['imagen_lista'],
            txt: item['title'],
            date: item['publish_date'],
            url: item['slug']
          });
        });
        return res;
      })
    );
  }

  public getNotDet(from: string) {
    return this._http.get<any[]>(`${ this.url }ultimas_noticias/${ from }/`);
  }

  public getNoticiasCat() {
    return this._http.get<any[]>(`${ this.url }categorias/`).pipe(
      map(data => {
        const tmp = data['results'].filter(cat => cat.modulo === 'noticias');
        const res: Object[] = [];
        tmp.forEach(item => {
          res.push({
            label: item['nombre'],
            url: item['nombre']
          });
        });
        return res;
      })
    );
  }

  public getEventosList() {
    return this._http.get<any[]>(`${ this.url }prox_partidos/`).pipe(
      map(data => {
        const res: IEventoDet[] = [];
        data['results'].forEach(item => {
          res.push({
            img_equipo1: item['escudo1'],
            nombre_equipo1: item['equipo1__nombre'],
            nombre_equipo2: item['equipo2__nombre'],
            img_equipo2: item['escudo2'],
            torneo: item['torneo__nombre'],
            fecha: item['fecha'],
            estadio: item['estadio__nombre'],
            fecha1: true,
            fecha_torneo: item['fecha_torneo']
          });
        });
        return res;
      })
    );
  }

  public getGalleries(filtro: number, offset?: number) {
    const url = (filtro !== 0) ?
      //`${ this.url }galeria/?limit=6&offset=${ offset }&categoria=${ filtro }` :
      //`${ this.url }galeria/?limit=6&offset=${ offset }`;
      `${ this.url }galeria/?limit=12&offset=${ offset }&categoria=${ filtro }` :
      `${ this.url }galeria/?limit=12&offset=${ offset }`;
    return this._http.get<any[]>(url).pipe(
      map((data: IGaleriaCard[] | string) => (typeof data === 'string') ? [] : data)
    );
  }

  public getGalleriesHome(filtro: number, offset?: number) {
    const url = (filtro !== 0) ?
      //`${ this.url }galeria/?limit=6&offset=${ offset }&categoria=${ filtro }` :
      //`${ this.url }galeria/?limit=6&offset=${ offset }`;
      `${ this.url }galeria/?limit=6&offset=${ offset }&categoria=${ filtro }` :
      `${ this.url }galeria/?limit=6&offset=${ offset }`;
    return this._http.get<any[]>(url).pipe(
      map((data: IGaleriaCard[] | string) => (typeof data === 'string') ? [] : data)
    );
  }

  public getSubgaleria(fr) {
    return this._http.get<any>(this.url + 'imagen_galeria/' + fr + '/').pipe(
      map(data => {
        const res: any[] = [];
        data.forEach(item => {
          res.push({
            c_imagen: item['imagen'],
            c_galeria: item['galeria']
          });
        });
        return res;
      })
    );
  }

  public getBanner() {
    return this._http.get<any>(this.url + 'banner_slider/');
  }

  public getJugadores() {
    return this._http.get<any>(this.url + 'jugador/');
  }

  public getTorneos() {
    return this._http.get<any>(this.url + 'torneos/');
  }

  public getPagzone(from: string) {
    return this._http.get<any[]>('assets/json/pagzone.json').pipe(
      map(data => data[from]));
  }

  // SEBAS STUFF

  public getPlantilla(id) {
    return this._http.get<any>(this.url + 'plantilla/' + id);
  }

  public getTabla_resultados() {
    return this._http.get<any>(this.url + 'tabla_completita/');
  }


  // FIN SEBAS STUFF


  public getPlaDet(from: string) {
    return this._http.get<any[]>(this.url + 'jugadorSLUG/' + from);
  }

  public getOtrosJugadores() {
    return this._http.get<IPlantillaCard[]>('assets/json/otrosJugadores.json');
  }

  public getJerList(from: string) {
    return this._http.get<any[]>('assets/json/jerarquias.json').pipe(map(data => data[from]));
  }

  public toHTML(input): any {
    return new DOMParser().parseFromString(input, 'text/html').documentElement.textContent;
  }

  public getZonasPaginas() {
    return this._http.get<any>(this.url + 'zona_pagina/');
  }

  public getZonasPaginas_especificas(identificador) {

    return this._http.get<any>(this.url + 'zona_pagina/' + identificador);
  }

  public getJugadoresHome() {
    return this._http.get<any>(this.url + 'Jugadores_top_primer_equipo/?limit=26&offset=0');
    /*return this._http.get<any>(this.url + 'Jugadores_top_primer_equipo/');*/
  }

  public getJugadoresFutFemHome() {
    return this._http.get<any>(this.url + 'Jugadores_top_fut_fem/?limit=26&offset=0');
  }

  public getJugadoresVoleyHome() {
    return this._http.get<any>(this.url + 'Jugadores_top_voley/?limit=26&offset=0');
  }

  public getFundacionAnios() {
    return this._http.get<any[]>(`${ this.url }fundacion/`).pipe(map(anios => {
      this.fundacion_arr = anios;
      const anios_arr = Object.keys(anios);
      let arr = new Object;
      for (let i = 0; i < anios_arr.length; i++) {
        arr = {
          ...arr,
          ... anios[i]
        };
      }
      return Object.keys(arr);
    }));
  }

  public getFundacionContent(anio: string) {
    return new Observable(observer => {
      const anios_arr = Object.keys(this.fundacion_arr);
      for (let i = 0; i < anios_arr.length; i++) {
        if (this.fundacion_arr[i][anio] !== undefined) {
          observer.next(this.fundacion_arr[i][anio]);
          observer.complete();
        }
      }
      observer.complete();
    });
  }

  public getPagina(from: string) {
    return this._http.get<any[]>(`${ this.url }pagina_interna/${ from }/`);
  }

  public getRevistasAnios() {
    return this._http.get<any[]>(`${ this.url }revistas-anios/`);
  }

  public getRevistas(year) {
    return this._http.get<any[]>(`${ this.url }revistas/?year=${year}`);
  }

  public getRevista(slug) {
    return this._http.get<any[]>(`${ this.url }revistas/${slug}/`);
  }

  public getSociosArea(member_token) {
    let headers = this._headers;
    if (member_token) {
      headers = headers.append('Member-Token', member_token);
    }
    return this._http.get<any[]>(`${ this.url }socios-area/`, {headers: headers});
  }

  public loginSocio(username, password) {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    return this._http.post<any[]>(`${ this.url }socios-login/`, formData);
  }

  public getStaff(id) {
    return this._http.get<any>(this.url + 'staff/' + id);
  }

  public getStaffDet(from: string) {
    return this._http.get<any[]>(this.url + 'staffSLUG/' + from);
  }

  public getPopups() {
    return this._http.get<any[]>(this.url + 'popups/');
  }
}

