import { Injectable } from '@angular/core';
import { ICounter } from '../models/common';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  menuToken = '';
  constructor() { }

  public counter: ICounter[] = [];
  public meses = [
    'ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'
  ];

  public formatFecha(f: Date): string {
    return `${ this.meses[f.getMonth()] } ${
      f.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) 
    }, ${ f.getFullYear() }`;
  }
  public formatHora(f: Date): string {
    return `${
      f.getHours().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
    }:${
      f.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})
    }`;
  }

  private _mycountdown(date: string, i: number) {
    const countDownDate = new Date(date).getTime();
    let now = new Date().getTime();
    let distance = countDownDate - now;

    if (distance < 0) {
      this.counter[i].days = '0';
      this.counter[i].hours = '0';
      this.counter[i].minutes = '0';
      this.counter[i].seconds = '0';
    } else {
      setInterval(() => {
        now = new Date().getTime();
        distance = countDownDate - now;
        this.counter[i].days = Math.floor(distance / (1000 * 60 * 60 * 24)).toString();
        this.counter[i].hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString();
        this.counter[i].minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toString();
        this.counter[i].seconds = Math.floor((distance % (1000 * 60)) / 1000).toString();
      }, 1000);
    }
  }

  public initCounter(date: string) {
    this.counter.push({days: '', hours: '', minutes: '', seconds: '' });
    this._mycountdown(date, this.counter.length - 1);
    return this.counter.length - 1;
  }

  public toggleMenu() {
    this.menuToken = (this.menuToken === '') ? 'displayMenu' : '';
  }
}
