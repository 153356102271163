import {Component, OnInit, Input} from '@angular/core';
import {DataService} from '../../../services/data.service';

@Component({
    selector: 'app-home-fichas',
    templateUrl: './home-fichas.component.html',
    styleUrls: ['./home-fichas.component.css']
})

export class HomeFichasComponent implements OnInit {

    @Input() showMePartially: boolean;

    fichas_slides = [];

    constructor(
        public dataService: DataService
    ) {

    }

    ngOnInit() {
        this.dataService.getJugadoresHome().toPromise()
            .then(jugadores => {
                this.fichas_slides = jugadores['results'];
            });
    }
}
