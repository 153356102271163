import { Component, OnInit, Input } from '@angular/core';
import { IMediaviewer } from '../../../models/shared';

@Component({
  selector: 'app-media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.css']
})
export class MediaViewerComponent implements OnInit {

  @Input() data: IMediaviewer;

  constructor() { }

  ngOnInit() {
  }

}
